export default [
  {
    header: 'routes.managementRoute.management',
    icon: 'icon-0-icons-dark-settings',
    children: [
      {
        title: 'routes.managementRoute.roles',
        route: 'roles',
        icon: 'icon-0-icons-dark-folded',
      },
      {
        title: 'routes.managementRoute.users',
        route: 'users',
        icon: 'icon-0-icons-dark-add-as-contact',
      },
    ],
  },
  {
    header: 'routes.documentalCollectionRoute.documentalCollection',
    icon: 'icon-0-icons-dark-folder',
    children: [
      {
        title: 'routes.documentalCollectionRoute.club',
        route: 'clubs',
        icon: 'icon-0-icons-dark-football',
      },
      {
        title: 'routes.documentalCollectionRoute.members',
        icon: 'icon-0-icons-dark-contacts',
      },
    ],
  },
]
